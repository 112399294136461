<template>
  <div id="app">
    <HelloWorld/>
  </div>
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'App',
  components: {
    HelloWorld
  }
}
</script>

<style>
html {
  background-image: url('~@/assets/image/back.png'); /* 替换为你的图片路径 */
  background-size: cover; /* 覆盖整个容器 */
  background-position: center; /* 居中背景图片 */
  background-repeat: no-repeat; /* 不平铺背景图片 */
  width: 100%; /* 容器宽度100% */
  height: 100vh; /* 容器高度视口高度，可以根据需要调整 */
}
</style>
